

<template>
  <div>
    <tbody>
      <tr>
        <th scope="row">Đặc biệt</th>
        <td colspan="12">
          <h1>{{ showResultNumber(27) }}</h1>
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(27) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row">Giải nhất</th>
        <td colspan="12">
          <h2>{{ showResultNumber(1) }}</h2>
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(1) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row">Giải nhì</th>
        <td colspan="6">
          {{ showResultNumber(2) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(2) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="6">
          {{ showResultNumber(3) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(3) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row" rowspan="2">Giải ba</th>
        <td colspan="4">
          {{ showResultNumber(4) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(4) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          {{ showResultNumber(5) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(5) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          {{ showResultNumber(6) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(6) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          {{ showResultNumber(7) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(7) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          {{ showResultNumber(8) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(8) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          {{ showResultNumber(9) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(9) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row">Giải nhì</th>
        <td colspan="3">
          {{ showResultNumber(10) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(10) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          {{ showResultNumber(11) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(11) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          {{ showResultNumber(12) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(12) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          {{ showResultNumber(13) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(13) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row" rowspan="2">Giải ba</th>
        <td colspan="4">
          {{ showResultNumber(14) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(14) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          {{ showResultNumber(15) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(15) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          {{ showResultNumber(16) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(16) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          {{ showResultNumber(17) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(17) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          {{ showResultNumber(18) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(18) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          {{ showResultNumber(19) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(19) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row">Giải ba</th>
        <td colspan="4">
          {{ showResultNumber(20) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(20) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          {{ showResultNumber(21) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(21) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          {{ showResultNumber(22) }}
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(22) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row">Giải ba</th>
        <td colspan="3">
          <h3>{{ showResultNumber(23) }}</h3>
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(23) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          <h3>{{ showResultNumber(24) }}</h3>
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(24) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          <h3>{{ showResultNumber(25) }}</h3>
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(25) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          <h3>{{ showResultNumber(26) }}</h3>
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(26) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
    </tbody>
    <table-footer />
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import TableFooter from "./TableFooter.vue";
export default {
  components: { PulseLoader, TableFooter },
  props: {
    rewards: Array,
    tag: String,
  },
  computed: {},
  created() {},
  data() {
    return { color: "#000", size: "6px" };
  },
  methods: {
    showResultNumber(id) {
      let number;
      for (var i = 0; i < this.rewards.length; i++) {
        if (this.tag === "start") {
          if (this.rewards[i].id === id) number = this.rewards[i].result;
        }
        if (this.tag === "wating") {
          if (this.rewards[i].showNo === id) number = this.rewards[i].value;
        }
      }
      return number;
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: 34px;
  font-weight: bold;
  color: maroon;
}
h2 {
  font-size: 28px;
  font-weight: bold;
}
h3 {
  font-size: 28px;
  font-weight: bold;
  color: maroon;
}

th {
  width: 6% !important;
}
td {
  width: 12% !important;
  font-size: 18px;
  font-weight: bold;
}
</style>
