import Vue from "vue";
import App from "./App.vue";
import VueSocketIO from "vue-socket.io";
import axios from "axios";
import store from "./store";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
const vuesocket = new VueSocketIO({
    debug: process.env.NODE_ENV !== "production" && false,
    connection: process.env.VUE_APP_BACKEND_API_URL || "http://localhost:8080",
    vuex: {
        store,
        actionPrefix: "SOCKET_",
        mutationPrefix: "SOCKET_",
    },
});

Vue.use(vuesocket);

new Vue({
    el: "#app",
    store,
    render: (h) => h(App),
}).$mount("#app");