<template>
  <div id="app">
    <div id="container">
      <Header />
      <Home />
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "./views/Header.vue";
import Home from "./views/Home.vue";

export default {
  name: "app",

  components: { Header, Home },
  data() {
    return {};
  },

  created() {},
  methods: {},
};
</script>

<style>
html {
  background: #f8f8f8;
}
#app {
  font-family: "Prompt", sans-serif;
  background: #500000;
}
#header {
  margin: 0px auto;
  width: 800px;
  text-align: center;
}

#container {
  width: 60%;
  /*height: 550px; */
  margin: 0px auto;
  padding: 10px;
  background: #f8f8f8;
}
h1,
h2,
h3,
h4,
h5,
h6,
b {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
th {
  font-size: 12px;
}
td {
  font-size: 12px;
}
.text-box {
  background: #500000;
  color: white;
}
@media (max-width: 1195px) {
  #container {
    width: 100%;
  }
}
</style>
